import { Col, Row } from 'antd'
import { useMediaQuery } from 'react-responsive'

import { Basket } from '@components/basket'
import { ClothesItem } from '@components/clothes-item/clothes-item'
import { ItemsList } from '@components/items-list/items-list'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { Block, ButtonLinkTo, Icon, Skeleton, Spinner } from '@ui/index'
import { useClothesPage } from './use-clothes-page'
// import { useLoaderData } from 'react-router-dom'

const CLOTH_SIZE = {
	width: 246,
	height: 443,
}

export function ClothesPage() {
	const {
		clothesList,
		handleAddNewCloth,
		handleChangeSearch,
		handleClothClick,
		isLoading,
		isNewCapsuleLoading,
		navigationButtons,
		showListType,
		handleClickCapsuleButton,
	} = useClothesPage()
	const isXxl = useMediaQuery({
		query: '(min-width: 1600px)',
	})
	const itemsPerPage = isXxl ? 6 : 4
	const loadersArr = new Array(itemsPerPage * 2 - 1).fill(1)

	if (isNewCapsuleLoading) return <Spinner />

	return (
		<Block isFullWidth margin="0 48px 48px">
			<PageNavigation
				showListType={showListType}
				buttons={navigationButtons}
				handleChangeSearch={handleChangeSearch}
				rightSideSlot={<></>}
			/>
			<Basket onCapsuleButtonClick={handleClickCapsuleButton} />

			<Row gutter={[48, 44]}>
				<Col
					xxl={{ span: 6 }}
					lg={{ span: 8 }}
					sm={{ span: 12, offset: 0 }}
					xs={{ span: 22, offset: 1 }}
				>
					<ButtonLinkTo
						onClick={handleAddNewCloth}
						title="Add new item"
						icon={<Icon name="clothAddIcon" size={36} />}
						minHeight="415px"
						maxWidth={CLOTH_SIZE.width + 'px'}
					/>
				</Col>
				<ItemsList
					isLoading={isLoading}
					itemsList={clothesList}
					loaderEl={
						<>
							{loadersArr.map((_, index) => (
								<Col
									key={index}
									xxl={{ span: 6 }}
									lg={{ span: 8 }}
									sm={{ span: 12, offset: 0 }}
									xs={{ span: 22, offset: 1 }}
								>
									<Skeleton width="100%" height={CLOTH_SIZE.height}>
										<rect
											x="0"
											y="0"
											rx="20"
											ry="20"
											width="100%"
											height={CLOTH_SIZE.height}
										/>
									</Skeleton>
								</Col>
							))}
						</>
					}
					renderItem={(item) => (
						<Col
							key={item.id}
							xxl={{ span: 6 }}
							lg={{ span: 8 }}
							sm={{ span: 12, offset: 0 }}
							xs={{ span: 22, offset: 1 }}
						>
							<ClothesItem
								clothItem={item}
								onClick={handleClothClick}
								maxWidth={CLOTH_SIZE.width + 'px'}
							/>
						</Col>
					)}
				/>
			</Row>
		</Block>
	)
}
