import { handleToast } from '@utils/handle-toast'
import axios from 'axios'
import { axiosInstance } from '../../config'

export async function updateCapsule(
	request: string,
	capsuleId: number,
	token: string
) {
	try {
		const response = await axiosInstance.patch(
			`/api/capsule/${capsuleId}`,
			request,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
			if (!!error?.response?.data.message) {
				handleToast({
					content: error?.response?.data.message,
				})
			}
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
