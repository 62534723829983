import { Col, Row } from 'antd'

import { Button } from '@ui/button'
import { handleToast } from '@utils/handle-toast'

import {
	BgCont,
	BlockWrapper,
	Container,
	ImageBlock,
	InterviewMsg,
	NoteText,
	TextBlockCol,
} from './client-payment-error-page-styled'

export function ClientPaymentErrorPage() {
	return (
		<Container>
			<BlockWrapper>
				<BgCont></BgCont>
				<Col xxl={{ span: 4 }} xl={{ span: 2 }}></Col>
				<Col xxl={{ span: 6 }} xl={{ span: 9 }}>
					<TextBlockCol>
						Oops! <br />
						Something went wrong with the payment
					</TextBlockCol>
					<InterviewMsg>
						The stylist will be booked once
						<br /> the payment is successful
					</InterviewMsg>
					<Row gutter={[12, 24]}>
						<Col flex="none">
							<Button
								isSubmit
								skin="dark"
								onClick={() => {
									handleToast({
										content: 'Go to payment - what route is needed?',
									})
								}}
								style={{ padding: '16px 36px' }}
								isFitContent
							>
								Go to payment
							</Button>
						</Col>
						<Col flex="1 1 200px">
							<NoteText>
								Please, <br />
								proceed with the payment
							</NoteText>
						</Col>
					</Row>
				</Col>
				<Col xxl={{ span: 14 }} xl={{ span: 13 }}>
					<ImageBlock />
				</Col>
			</BlockWrapper>
		</Container>
	)
}
