import { UpdateItemResponse } from '@typings/types'
import { handleToast } from '@utils/handle-toast'
import axios from 'axios'
import { axiosInstance } from '../../config'

export async function updateItem(
	request: string,
	itemId: number,
	token: string
) {
	try {
		const response = await axiosInstance.patch<UpdateItemResponse>(
			`/api/item/${itemId}`,
			request,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
			if (!!error?.response?.data.message) {
				handleToast({
					content: error?.response?.data.message,
				})
			}
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
