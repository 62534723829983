import { PROD_DOMAIN } from '@typings/constants'
import { DownloadedFile } from '@typings/types'
import { Button } from '@ui/button'
import { GrayLine } from '@ui/gray-line/gray-line'
import { Icon } from '@ui/icon'
import { Dropdown, Row } from 'antd'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {
	ActionsWrapper,
	Container,
	FileWrapper,
	ImageWrapper,
	InfoWrapper,
	StatusWrapper,
	StyledCol,
	Time,
	Title,
} from './gallery-block-styled'

type Props = {
	carouselRef: any
	itemsList: (
		| { item: DownloadedFile; type: 'file' }
		| { item: string; type: 'link' }
	)[]
	onLinkDelete: (link: string) => void
	onFileDelete: (index: number) => void
}

const isImageFile = (
	itemObj:
		| { item: DownloadedFile; type: 'file' }
		| { item: string; type: 'link' }
) => {
	const extArray = ['jpg', 'jpeg', 'png']

	return (
		itemObj.type === 'file' &&
		extArray.includes(itemObj.item.urls.origin.split('.')[1])
	)
}
export function GalleryBlock({
	carouselRef,
	itemsList,
	onLinkDelete,
	onFileDelete,
}: Props) {
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 6,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
			partialVisibilityGutter: 40,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	}

	return (
		<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 48 }}>
			<StyledCol span={24}>
				<Carousel
					ref={(el) => (carouselRef.current = el)}
					responsive={responsive}
					arrows={false}
					containerClass="carousel-container"
					itemClass="cv-form-gallery-item"
					renderButtonGroupOutside={true}
					centerMode={false}
				>
					{itemsList.map((obj, index) => (
						<Container
							key={obj.type === 'file' ? 'file' + index : 'link' + index}
						>
							{obj.type === 'file' && isImageFile(obj) && (
								<FileWrapper imageUrl={PROD_DOMAIN + obj.item.urls.origin} />
							)}
							{!isImageFile(obj) && (
								<ImageWrapper>
									<Icon
										name={obj.type === 'file' ? 'filePlusIcon' : 'linkIcon'}
										color="black"
										size={24}
									/>
								</ImageWrapper>
							)}
							<Dropdown
								menu={{
									items: [
										{
											key: '1',
											label: (
												<Button
													skin="light"
													size="m"
													onClick={() => {
														obj.type === 'file'
															? onFileDelete(obj.item.file)
															: onLinkDelete(obj.item)
													}}
												>
													Delete
												</Button>
											),
										},
									],
								}}
								placement="bottomRight"
								arrow={{ pointAtCenter: true }}
							>
								<ActionsWrapper>
									<Icon name="threeDotsIcon" size={24} />
								</ActionsWrapper>
							</Dropdown>
							<InfoWrapper>
								<Title>
									{obj.type === 'file' ? (
										obj.item.urls.origin
									) : (
										<a target="_blank" href={obj.item} rel="noreferrer">
											{obj.item}
										</a>
									)}
								</Title>
								<Time>added 23/09/2022</Time>
							</InfoWrapper>
							<GrayLine />
							<StatusWrapper>
								<Icon name="addedItemIcon" color="#1B998B" size={24} />
								<span>Done</span>
							</StatusWrapper>
						</Container>
					))}
				</Carousel>
			</StyledCol>
		</Row>
	)
}
