import styled, { css } from 'styled-components'
import { screenSize } from './../../../styles/screen-sizes'

import EllipseWoman from '@assets/EllipseWoman.png'
import LoginBG from '@assets/loginBG.png'
import { colors } from '@styles/colors'
import {
	fontPlayfair,
	fontTenorSans,
	regular26,
	regular42,
} from '@styles/typography'

export const BgCont = styled.div`
	background: url(${LoginBG});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.5;
`

export const GapLineCol = styled.div`
	display: flex;
	width: 48px;
	margin: 136px auto;
	border-top: 2px solid ${colors.black};
`
export const TextBlockCol = styled.div`
	${fontPlayfair}
	${regular42}

	margin: 35px auto 70px;

	@media ${screenSize.antLg} {
		margin: 35px auto 70px;
	}
	@media ${screenSize.antXxl} {
		margin: 135px auto 70px;
	}
`

export const InterviewMsg = styled.div`
	${fontTenorSans}
	${regular26}
	align-items: center;
	margin: 0 auto 70px;
`
export const Red = styled.i`
	color: red;
`

export const ImageBlock = styled.div`
	position: relative;
	background: url(${EllipseWoman});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
	width: 100%;
	height: 100%;
	max-width: 765px;
	max-height: 660px;
	margin: 62px 0 0 65px;
`

export const ClientAvatar = styled.div<{ imageUrl: string }>`
	width: 100%;
	max-width: 400px;
	aspect-ratio: 1;
	border-radius: 50%;
	overflow: hidden;

	${({ imageUrl }) =>
		!!imageUrl &&
		css`
			background: url(${imageUrl});
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
		`}
`

export const AvatarEmpty = styled.div`
	position: relative;
	width: 100%;
	max-width: 400px;
	aspect-ratio: 1;
	border-radius: 50%;
	overflow: hidden;
	background: #dcdcdc;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const StylistAvatar = styled.div<{ imageUrl: string }>`
	width: 100%;
	max-width: 400px;
	aspect-ratio: 1;
	border-radius: 50%;
	overflow: hidden;
	margin-left: -50px;
	z-index: 200;

	${({ imageUrl }) =>
		!!imageUrl &&
		css`
			background: url(${imageUrl});
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
		`}
`

export const SDLogo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	background: ${colors.black};
	padding: 9px;
	border-radius: 50%;
	overflow: hidden;
	margin-left: -18px;
	margin-top: -90px;

	@media ${screenSize.antXs} {
		margin-left: -18px;
		margin-top: -100px;
	}

	@media ${screenSize.antSm} {
		width: 70px;
		height: 70px;

		margin-left: -20px;
		margin-top: -140px;
	}

	@media ${screenSize.antMd} {
		width: 50px;
		height: 50px;

		margin-left: -18px;
		margin-top: -90px;
	}

	@media ${screenSize.antLg} {
		width: 80px;
		height: 80px;

		margin-left: -33px;
		margin-top: -140px;
	}

	@media ${screenSize.antXl} {
		width: 80px;
		height: 80px;

		margin-left: -25px;
		margin-top: -190px;
	}
`
