import { Col, Row } from 'antd'

import Avatar from '@components/avatar/avatar'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { Block } from '@ui/block'
import { GrayLine } from '@ui/gray-line/gray-line'
import {
	ButtonStyled,
	DescriptionStyled,
	InfoCont,
	ModalStyled,
	RowStyled,
	StatusTitle,
	StylistInfoBlock,
	StylistInfoContainer,
	StylistName,
	TextCont,
	TimeWrapper,
} from './stylist-client-details-styled'

import { useBookingInfo } from '@components/booking-info/use-booking-info'
import { ClientStyleBoardPreview } from '@components/client-styleboard-preview/client-styleboard-preview'
import { SdNotification } from '@components/sd-notification'
import { Button } from '@ui/button'
import { CounterWrapper } from '@ui/button/button-styled'
import { Spinner } from '@ui/spinner'
import { Swap } from '@ui/swap'
import { ClientCapsulesBlock } from './client-capsules/client-capsules'
import { PersonalDataForm } from './personal-data-form/personal-data-form'
import { PreferencesDataForm } from './preferences-data-form/preferences-data-form'
import { useStylistClientDetails } from './use-stylist-client-details'

export default function StylistClientDetails() {
	const {
		dueDate,
		clientDetails,
		dealDetails,
		showListType,
		buttons,
		isLoading,
		handleActionApply,
		stylistInfo,
		clientNavButtons,
		showClientDetailsListType,
		isModalOpen,
		handleOk,
		handleCancel,
		statusData,
		isChatLoading,
		unreadMessagesCount,
	} = useStylistClientDetails()

	const { dueDateTimer, isExpired } = useBookingInfo({
		dealStatus: dealDetails?.dealStatus,
	})

	return (
		<Block isFullWidth isContentCentered>
			<PageNavigation
				isShowAllBtn={false}
				showListType={showListType}
				buttons={buttons}
			/>
			<RowStyled gutter={{ xs: 12, sm: 24, md: 48, lg: 48 }}>
				<Swap is={isLoading} isSlot={<Spinner color="#FF5656" />}>
					<>
						<Col
							xs={24}
							sm={24}
							md={24}
							lg={24}
							xl={{ span: 8, offset: 0 }}
							xxl={{ span: 6, offset: 0 }}
						>
							<StylistInfoContainer>
								<Avatar
									imageUrl={clientDetails?.params?.photo?.[0].urls?.M || ''}
									size="100%"
								/>
								<StylistInfoBlock>
									<StylistName>
										{clientDetails?.first_name} {clientDetails?.last_name}
									</StylistName>
									<GrayLine style={{ margin: '20px 0' }} />
									<InfoCont>
										<TextCont>
											{dealDetails?.dealStatus?.statusText && (
												<SdNotification
													text={dealDetails?.dealStatus?.statusText}
												/>
											)}
										</TextCont>
									</InfoCont>
									<GrayLine style={{ margin: '20px 0' }} />
									<Row gutter={[20, 20]}>
										{!!dealDetails?.dealStatus?.stylistActions?.length &&
											dealDetails?.dealStatus?.stylistActions.map(
												(action, index) => (
													<Col
														xs={{ span: 24 }}
														sm={{ span: 24 }}
														md={{ span: 12, offset: 6 }}
														lg={{ span: 12, offset: 6 }}
														xl={{ span: 24, offset: 0 }}
														xxl={{ span: 16, offset: 4 }}
														key={index}
													>
														<ButtonStyled
															isDisabled={
																!action.enabled ||
																(action.action === 'chat_open' && isChatLoading)
															}
															skin={
																action.action === 'book_decline'
																	? 'light-link'
																	: 'dark'
															}
															size="m"
															onClick={() => handleActionApply(action.action)}
															isLoading={
																action.action === 'chat_open' && isChatLoading
															}
															rightIcon={
																action.action === 'chat_open' &&
																!!unreadMessagesCount && (
																	<CounterWrapper>
																		{unreadMessagesCount}
																	</CounterWrapper>
																)
															}
														>
															{action.button_text}
														</ButtonStyled>
													</Col>
												)
											)}
									</Row>
									<GrayLine style={{ margin: '15px 0' }} />
									{dealDetails?.dealStatus?.status && (
										<Block
											isFlexBlock
											flexDirection="column"
											isFullWidth
											isContentCentered
											gap="6px"
										>
											<StatusTitle>
												{statusData[dealDetails.dealStatus.status].title}
											</StatusTitle>
											{dealDetails?.dealStatus?.statusDeadline && (
												<>
													<TimeWrapper>
														<span>until</span> {dueDate}
													</TimeWrapper>
													{dealDetails.dealStatus.status !== 'done' && (
														<TimeWrapper isExpired={isExpired}>
															<span>in</span> {dueDateTimer}
														</TimeWrapper>
													)}
												</>
											)}
										</Block>
									)}
								</StylistInfoBlock>
							</StylistInfoContainer>
						</Col>
						<Col
							xs={24}
							sm={24}
							lg={{ span: 24 }}
							xl={{ span: 16 }}
							xxl={{ span: 18 }}
						>
							<PageNavigation
								isShowAllBtn={false}
								showListType={showClientDetailsListType}
								buttons={clientNavButtons}
							/>
							<RowStyled gutter={{ xs: 12, sm: 24, md: 48 }}>
								{showClientDetailsListType === 'artifacts' && (
									<>
										<Col
											xs={24}
											sm={24}
											lg={{ span: 24 }}
											xl={{ span: 12 }}
											xxl={{ span: 10 }}
										>
											<ClientStyleBoardPreview
												clientId={clientDetails?.id}
												styleBoard={dealDetails?.details?.styleboards?.[0]}
												stylistInfo={stylistInfo}
												dealStatus={dealDetails?.dealStatus?.status}
											/>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={{ span: 24 }}
											xl={{ span: 12 }}
											xxl={{ span: 14 }}
										>
											<ClientCapsulesBlock
												isLoading={isLoading}
												elPerRow={2}
												capsulesList={
													dealDetails?.details?.capsules?.[0]
														? dealDetails?.details?.capsules
														: []
												}
												dealStatus={dealDetails?.dealStatus?.status}
											/>
										</Col>
									</>
								)}
								{showClientDetailsListType === 'preferences' && (
									<Col>
										{clientDetails && (
											<RowStyled gutter={{ xs: 12, sm: 24, md: 48, lg: 48 }}>
												<PersonalDataForm clientDetails={clientDetails} />
												<PreferencesDataForm clientDetails={clientDetails} />
											</RowStyled>
										)}
									</Col>
								)}
							</RowStyled>
						</Col>
					</>
				</Swap>
			</RowStyled>

			<ModalStyled
				title=""
				open={isModalOpen}
				centered
				closeIcon={false}
				footer={[
					<Button key="back" onClick={handleCancel} skin="light-link">
						No
					</Button>,
					<Button key="submit" onClick={handleOk} skin="dark">
						Decline
					</Button>,
				]}
			>
				<p>
					Oops! <br />
					<br />
					Are you sure you want to decline this client?
				</p>
				<DescriptionStyled>
					You won’t be able to reverse this action
				</DescriptionStyled>
			</ModalStyled>
		</Block>
	)
}
