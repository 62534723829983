import { ImageItemType } from '@typings/types'
import { Col, Row } from 'antd'
import { AddPhotoButton } from './add-photo-button'
import { PhotosList } from './photos-list'

type Props = {
	isNewPhotoLoading: boolean
	imageItems: ImageItemType[]
	handleAddNewPhoto: (e: any) => void
	handleSetCoverClick: (id: number) => void
	handleDeleteClick: (id: number) => void
	aspect?: number
}

// TODO: A refactoring is needed here to use one component throughout the entire project.
export function GalleryBlock({
	aspect,
	imageItems,
	isNewPhotoLoading,
	handleAddNewPhoto,
	handleSetCoverClick,
	handleDeleteClick,
}: Props) {
	return (
		<Row gutter={[16, 16]}>
			<Col
				xs={{ span: 24 }}
				sm={{ span: 8 }}
				md={{ span: 8 }}
				xxl={{ span: 6 }}
				xl={{ span: 8 }}
			>
				<AddPhotoButton
					aspect={aspect}
					isNewPhotoLoading={!!isNewPhotoLoading}
					handleAddNewPhoto={handleAddNewPhoto}
				/>
			</Col>
			<PhotosList
				imageItems={imageItems}
				handleSetCoverClick={handleSetCoverClick}
				handleDeleteClick={handleDeleteClick}
			/>
		</Row>
	)
}
