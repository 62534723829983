import styled, { css } from 'styled-components'

import { colors } from '@styles/colors'
import { screenSize } from '@styles/screen-sizes'
import {
	fontRoboto,
	fontTenorSans,
	regular13,
	regular14,
	regular16,
	regular32,
} from '@styles/typography'
import { Row } from 'antd'

export const ClothNumber = styled.span`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	margin-left: 8px;
`

export const ClothesContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, 142px);
	grid-gap: 16px;

	width: 100%;
`

export const CategoryList = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`

export const CategoryItem = styled.div`
	${fontRoboto}
	${regular14}
  text-transform: capitalize;

	padding: 6px;
	border: 1px solid rgba(0, 0, 0, 0.16);
	border-radius: 8px;
`

export const ColorCircle = styled.div<{ color?: string }>`
	width: 24px;
	height: 24px;
	border: 2px solid #ffffff;
	border-radius: 30px;
	align-self: center;

	margin-right: -10px;

	${({ color }) =>
		color &&
		css`
			background: ${color};
		`}
`

export const Price = styled.div`
	display: flex;
	gap: 4px;
	${fontTenorSans}
	${regular32}
  color: ${colors.black};
	padding: 10px 16px 10px 0;
	align-self: start;
	min-height: 24px;
`

export const ColorWrapper = styled.div`
	display: flex;
	padding: 10px 16px 10px 0;
	align-self: start;
	min-height: 60px;
	align-content: center;
`

export const ShowcaseBtnText = styled.span`
	${fontRoboto}
	${regular16}
  display: flex;
	height: 100%;
	align-items: center;
	letter-spacing: 0.01em;
`

export const ShareModalText = styled.div`
	${fontTenorSans}
	${regular16}
  color: #000;
	text-align: center;
`

export const ClientBlock = styled.div`
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */

	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px;

	border-radius: 8px;
	border: 1px solid var(---16, rgba(0, 0, 0, 0.16));
`

export const RowStyled = styled(Row)`
	margin-bottom: 24px;
	margin-top: 34px;

	@media ${screenSize.antSm} {
		margin-bottom: 24px;
		margin-top: 34px;
	}
	@media ${screenSize.antMd} {
		margin-bottom: 34px;
		margin-top: 54px;
	}
	@media ${screenSize.antLg} {
		margin-bottom: 44px;
		margin-top: 64px;
	}
`
