import { PreferencesBlock } from '@components/preferences-block/preferences-block'
import { ClientInfo } from '@typings/types'
import { InfoTextBlock } from '@ui/index'
import { Col, Row } from 'antd'
import { TitleBlock } from '../personal-data-form/personal-data-form-styled'
import { SelectWrapperRow } from './preferences-data-form-styled'

export function PreferencesDataForm({
	clientDetails,
}: {
	clientDetails: ClientInfo
}) {
	const client = clientDetails

	return (
		<Col xs={{ span: 24 }} xl={{ flex: '0 1 66%' }}>
			<Row gutter={[48, 36]}>
				<Col
					xs={{ span: 24 }}
					md={{ flex: '0 1 49%' }}
					xl={{ flex: '0 1 49%' }}
				>
					<TitleBlock>Sizes data</TitleBlock>
					<SelectWrapperRow gutter={[16, 36]} justify={'space-between'}>
						<Col span={12}>
							<InfoTextBlock
								text={client.params?.topSize?.[0] || ''}
								label={'Top'}
							/>
						</Col>
						<Col span={12}>
							<InfoTextBlock
								text={client.params?.bottomSize?.[0] || ''}
								label={'Bottom'}
							/>
						</Col>
						<Col span={12}>
							<InfoTextBlock
								text={client.params?.dressSize?.[0] || ''}
								label={'Dress'}
							/>
						</Col>
						<Col span={12}>
							<InfoTextBlock
								text={client.params?.shoesSize?.[0] || ''}
								label={'Shoes'}
							/>
						</Col>
					</SelectWrapperRow>
				</Col>
				<Col
					xs={{ span: 24 }}
					md={{ flex: '0 1 49%' }}
					xl={{ flex: '0 1 49%' }}
				>
					<TitleBlock>Expenses Data</TitleBlock>
					<SelectWrapperRow gutter={[16, 36]} justify={'space-between'}>
						<Col span={12}>
							<InfoTextBlock
								text={client.params?.topExpense?.[0] || ''}
								label={'Top'}
							/>
						</Col>
						<Col span={12}>
							<InfoTextBlock
								text={client.params?.bottomExpense?.[0] || ''}
								label={'Bottom'}
							/>
						</Col>
						<Col span={12}>
							<InfoTextBlock
								text={client.params?.dressExpense?.[0] || ''}
								label={'Dress'}
							/>
						</Col>
						<Col span={12}>
							<InfoTextBlock
								text={client.params?.shoesExpense?.[0] || ''}
								label={'Shoes'}
							/>
						</Col>
					</SelectWrapperRow>
				</Col>
			</Row>
			<Row gutter={[0, 36]} style={{ marginTop: 60 }}>
				<Col flex="0 1 100%">
					<TitleBlock>Data for stylist</TitleBlock>
				</Col>
				<Col flex="0 1 100%">
					<InfoTextBlock
						text={client.params.categories?.join(', ') || ''}
						label={'What wardrobe items to include in capsule'}
					/>
				</Col>

				{/* TODO: Remove isPreview flag when setClientPreferences API will be ready */}
				<PreferencesBlock
					isHideFigure
					isPreview
					user={{
						id: client.id,
						role: 'client',
					}}
				/>
			</Row>
		</Col>
	)
}
