import { Modal, Row } from 'antd'
import styled from 'styled-components'

import { colors } from '@styles/colors'
import { screenSize } from '@styles/screen-sizes'
import { fontTenorSans, regular18 } from '@styles/typography'
import { Button } from '@ui/button'
import {
	bold16,
	fontRoboto,
	regular13,
	regular16,
	regular26,
} from '../../styles/typography'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 24% 33% 40%;
	grid-gap: 48px;
`

export const StylistInfoContainer = styled.div`
	display: block;
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;
`
export const SectionBlock = styled.div`
	padding: 32px 40px;
	border-radius: 20px;
	background: ${colors.blackTr5};
`

export const SectionTitle = styled.div`
	${fontTenorSans}
	${regular18}

	&>span {
		margin-right: 10px;
	}
`

export const StylistInfoBlock = styled.div`
	position: relative;
	margin-top: -25%;
	padding: 20px 24px 24px;
	background: ${colors.white};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 12px;
`

export const StylistName = styled.div`
	${fontTenorSans}
	${regular26}

	color: ${colors.black};
`

export const ButtonStyled = styled(Button)``

export const ButtonMessenger = styled(Button)`
	& > div {
		margin-right: 0;
	}
`

export const BlockTitle = styled.div`
	${fontRoboto}
	${bold16}
  margin-bottom: 8px;
`
export const ModalStyled = styled(Modal)`
	& .ant-modal-close {
		color: ${colors.mainThemeColor};

		&:hover {
			color: ${colors.mainThemeColor};
		}
	}

	& .ant-modal-content {
		padding: 56px;
		text-align: center;
	}

	& .ant-modal-body {
		${fontTenorSans}
		${regular26}
		margin-bottom: 48px;
	}

	& .ant-modal-footer {
		display: flex;
		gap: 10px;
		justify-content: space-between;
	}
`

export const InfoCont = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: no-wrap;
	gap: 16px;
	align-items: center;
`
export const TextCont = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	overflow: hidden;
`

export const AdditionalInfo = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr64};

	line-height: 24px;
	min-height: 24px;
	text-align: right;
`

export const TimeWrapper = styled.div<{ isExpired?: boolean }>`
	${fontRoboto}
	${regular16}

	color: ${(props) => (props.isExpired ? 'red' : colors.black)};

	span {
		color: ${colors.blackTr64};
		${regular13}
	}
`
export const TitleFlex = styled.div`
	display: flex;
	flex-flow: row;
	gap: 20px;
`

export const LinkCont = styled.div`
	${fontRoboto}
	${regular16}
  color: #FF0C63;
	letter-spacing: 0.16px;
	text-decoration-line: underline;
`

export const StatusTitle = styled.div`
	color: ${colors.black};
	${regular18}
	${fontTenorSans}
  margin-bottom: 10px;
`

export const DescriptionStyled = styled.div`
	${regular16}
	${fontTenorSans}

	color: ${colors.black};
	margin-top: 24px;
`

export const RowStyled = styled(Row)`
	@media (${screenSize.antXs}) {
		padding: 0 8px;
		gap: 30px;
	}
	@media (${screenSize.antSm}) {
		padding: 0 8px;
		gap: 30px;
	}
	@media (${screenSize.antMd}) {
		padding: 0 12px;
		gap: 30px;
	}
	@media (${screenSize.antLg}) {
		padding: 0 12px;
		gap: 30px;
	}
	@media (${screenSize.antXl}) {
		padding: 0;
		gap: 0;
	}
	@media (${screenSize.antXxl}) {
		padding: 0;
		gap: 0;
	}
`
