import LoginBG from '@assets/loginBG.png'
import { colors } from '@styles/colors'
import { size } from '@styles/screen-sizes'
import { Row } from 'antd'
import styled from 'styled-components'
import { regular16 } from '../../styles/typography'
import { fontPlayfair, regular42 } from './../../styles/typography'

export const Container = styled.div`
	width: 100%;
	height: 100%;
	${regular16}
	background: url(${LoginBG}),
	${colors.white};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@media (max-width: ${size.maxTablet}) {
		padding: 0 25px;
	}
`

export const RowStyled = styled(Row)`
	margin-top: 90px;

	@media (max-width: ${size.maxTablet}) {
		margin-top: 0;
	}
`

export const NotFoundTextDiv = styled.div`
	${fontPlayfair}
	${regular42}
	color: ${colors.black};
	letter-spacing: 0.84px;
	display: flex;
	align-items: center;
	height: 640px;

	@media (max-width: ${size.maxTablet}) {
		height: 340px;
	}
`
export const NotFoundImgWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	img {
		max-width: 640px;
		width: 100%;
	}
`
