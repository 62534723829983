import Img404 from '@assets/404.png'
import { GapLineCol } from '@components/quiz/quiz-styled'
import { useLoginPage } from '@pages/login/use-login-page'
import { Col } from 'antd'
import {
	Container,
	NotFoundImgWrapper,
	NotFoundTextDiv,
	RowStyled,
} from './not-found-page-styled'

export function NotFoundPage() {
	const { isLoading } = useLoginPage()

	if (isLoading) return null

	return (
		<Container>
			<RowStyled>
				<Col span={3} offset={1} style={{ marginTop: 30 }}>
					<GapLineCol />
				</Col>
				<Col md={{ span: 24 }} lg={{ span: 6 }}>
					<NotFoundTextDiv>
						Oops! Something went wrong. Page doesn’t exist.
					</NotFoundTextDiv>
				</Col>
				<Col
					md={{ span: 24 }}
					lg={{ span: 10, offset: 1 }}
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					<NotFoundImgWrapper>
						<img src={Img404} alt="page doesn’t exist" />
					</NotFoundImgWrapper>
				</Col>
			</RowStyled>
		</Container>
	)
}
