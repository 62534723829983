import { Navigate } from 'react-router-dom'
import { usePersonalDataForm } from '../../components/stylist-registration-form/personal-data/use-personal-data-form'
import { WaitApproveScreen } from '../../components/stylist-registration-form/wait-approve-screen/wait-approve-screen'

export function StylistWaitApprovePage() {
	const { userInfo } = usePersonalDataForm()

	if (userInfo.status !== 'interview-wait') {
		return <Navigate to={'/stylist-profile'} />
	}

	return <WaitApproveScreen name={userInfo.stylist?.first_name} />
}
