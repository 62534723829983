import { screenSize } from '@styles/screen-sizes'
import { Row } from 'antd'
import styled from 'styled-components'

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 48px;

	margin-top: 20px;

	& > button {
		width: 100%;
	}
`

export const RowStyled = styled(Row)`
	margin-bottom: 24px;
	margin-top: 34px;

	@media ${screenSize.antSm} {
		margin-bottom: 24px;
		margin-top: 34px;
	}
	@media ${screenSize.antMd} {
		margin-bottom: 34px;
		margin-top: 54px;
	}
	@media ${screenSize.antLg} {
		margin-bottom: 44px;
		margin-top: 64px;
	}
`
