import { Col, Row } from 'antd'
import moment from 'moment'
import { useRef } from 'react'

import { AvatarClientBlock } from '@components/avatar-client-block'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { colors } from '@styles/colors'
import { PROD_DOMAIN } from '@typings/constants'
import {
	BlankPlaceholder,
	Block,
	Button,
	GrayLine,
	RadioButton,
	Spinner,
	Swap,
} from '@ui/index'
import {
	CategoryItem,
	CategoryList,
	ColorCircle,
	ColorWrapper,
	Price,
} from '../capsules-edit-page/capsules-edit-page-styled'
import {
	CapsuleCollageWrapper,
	ColFlex,
	ColParamLabel,
	CreatedDate,
	ImageCont,
	ShowcaseBtnText,
} from './capsules-preview-page-styled'
import { GalleryBlock } from './gallery-block/gallery-block'
import { useCapsulePreviewPage } from './use-capsules-preview-page'

export function CapsulesPreviewPage() {
	const {
		clientLabel,
		clientAvatarUrl,
		capsule,
		currentPage,
		navigationButtons,
		imageItems,
		isHomepageRadioActive,
		settings,
		isCapsuleLoading,
		categoriesToShow,
		isShortCategoriesList,
		isShowAllCategories,
		handleToggleCategoriesList,
	} = useCapsulePreviewPage()

	const carouselRef = useRef<any>()

	const renderCategories = () => (
		<CategoryList>
			{categoriesToShow.map(([key, value], index) => {
				return <CategoryItem key={index}>{`${key} (${value})`}</CategoryItem>
			})}
			{isShowAllCategories && (
				<Button
					skin="light-link"
					isFitContent
					size="s"
					onClick={handleToggleCategoriesList}
				>
					{isShortCategoriesList ? 'Show All' : 'Hide'}
				</Button>
			)}
		</CategoryList>
	)

	const renderColors = () => {
		const colors = capsule.summary?.color
		const storeColors = settings.ItemColors

		if (!colors) return null

		return (
			<ColorWrapper>
				{Object.entries(colors).map(([key, value]) => {
					return (
						<ColorCircle key={key} color={storeColors?.[key]}></ColorCircle>
					)
				})}
			</ColorWrapper>
		)
	}

	const fullImageUrl = capsule.params?.coverPhoto?.[0].urls.M
		? PROD_DOMAIN + capsule.params.coverPhoto[0].urls.M
		: ''

	return (
		<Block isFullWidth margin="0 48px 48px">
			<PageNavigation showListType={currentPage} buttons={navigationButtons} />
			<Row gutter={[48, 48]}>
				<Swap is={isCapsuleLoading} isSlot={<Spinner color="#ff0c63" />}>
					<>
						<Col
							xs={{ span: 24 }}
							md={{ span: 24 }}
							lg={{ span: 12 }}
							xl={{ span: 8 }}
							xxl={{ span: 6 }}
						>
							<CapsuleCollageWrapper>
								<ImageCont imageUrl={fullImageUrl}>
									{!fullImageUrl && (
										<BlankPlaceholder color={colors.blackTr24} />
									)}
								</ImageCont>
							</CapsuleCollageWrapper>
						</Col>
						<Col
							xs={{ span: 24 }}
							md={{ span: 24 }}
							lg={{ span: 12 }}
							xl={{ span: 14 }}
							xxl={{ span: 6 }}
						>
							<Row gutter={[12, 12]}>
								<Col flex="0 1 170px" className="row-block-label">
									Description
								</Col>
								<ColFlex flex="1 1 170px">
									<ShowcaseBtnText>Show on my profile</ShowcaseBtnText>
									<RadioButton
										isDisabled={!!clientLabel}
										onClick={() => {}}
										isActive={isHomepageRadioActive}
										style={{ cursor: 'default' }}
									/>
								</ColFlex>
							</Row>
							<Row gutter={24} style={{ marginTop: 48 }}>
								<ColParamLabel span={8}>Category</ColParamLabel>
								<Col span={16}>{renderCategories()}</Col>
								<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
									<GrayLine />
								</Col>
								<ColParamLabel span={8}>Linked to</ColParamLabel>
								<Col span={16} style={{ overflow: 'hidden' }}>
									{clientLabel && (
										<AvatarClientBlock
											clientName={clientLabel}
											imgUrl={clientAvatarUrl}
										/>
									)}
								</Col>
								<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
									<GrayLine />
								</Col>
								<ColParamLabel span={8}>Colours</ColParamLabel>
								<Col span={16}>{renderColors()}</Col>
								<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
									<GrayLine />
								</Col>
								<ColParamLabel span={8}>Created</ColParamLabel>
								<Col span={16}>
									<CreatedDate>
										{moment(capsule.created_at).format('DD, MMM YYYY')}
									</CreatedDate>
								</Col>
								<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
									<GrayLine />
								</Col>
								<ColParamLabel span={8}>Overall budget</ColParamLabel>
								<Col span={16}>
									<Price>
										<p>{capsule.summary?.priceValute}</p>
										<p>{capsule.summary?.price}</p>
									</Price>
								</Col>
							</Row>
						</Col>
						<Col
							xs={{ span: 24 }}
							md={{ span: 24 }}
							lg={{ span: 24 }}
							xl={{ span: 24 }}
							xxl={{ span: 12 }}
						>
							<GalleryBlock carouselRef={carouselRef} imageList={imageItems} />
						</Col>
					</>
				</Swap>
			</Row>
		</Block>
	)
}
