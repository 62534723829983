import { getClientPreferences } from '@api/client/get-preferences-list'
import { getStylistPreferences } from '@api/stylist/get-stylist-preferences'
import { setStylistPreferences } from '@api/stylist/set-stylist-preferences'
import { authSelector } from '@store/auth'
import { useAppDispatch, useAppSelector } from '@store/store'
import {
	setIsPreferencesLoading,
	setUserPreferences,
	userSelector,
} from '@store/user'
import { PreferenceTag, UserPreferences } from '@typings/types'
import { Block } from '@ui/block'
import { CheckBoxesComponent, SelectOptions } from '@ui/checkboxes-component'
import { Spinner } from '@ui/spinner'
import { useCallback, useEffect, useMemo } from 'react'

type Props = {
	isPreview?: boolean
	isHideFigure?: boolean
	user: {
		id: number
		role: 'client' | 'stylist'
	}
}

export function PreferencesBlock({ isPreview, isHideFigure, user }: Props) {
	const dispatch = useAppDispatch()
	const { token } = useAppSelector(authSelector)
	const { userInfo, userPreferences, isPreferencesLoading } =
		useAppSelector(userSelector)

	const filteredUserPreferences = useMemo(
		() =>
			isHideFigure
				? userPreferences.filter((preference) => preference.title !== 'Figure')
				: userPreferences,
		[userPreferences, isHideFigure]
	)

	const handleLoadPreferences = useCallback(async () => {
		dispatch(setIsPreferencesLoading(true))

		let preferencesData: UserPreferences[] | undefined = []
		if (user.role === 'stylist') {
			preferencesData = await getStylistPreferences({
				token,
				id: user.id,
			})
		}

		if (user.role === 'client') {
			preferencesData = await getClientPreferences({
				token,
				id: user.id,
			})
		}

		if (preferencesData) {
			dispatch(setUserPreferences(preferencesData))
		}

		dispatch(setIsPreferencesLoading(false))
	}, [user, dispatch, token])

	useEffect(() => {
		if (isPreferencesLoading) return
		handleLoadPreferences()

		return () => {
			dispatch(setUserPreferences([]))
		}
	}, [])

	const hasCheckedPreferences = useMemo(
		() =>
			userPreferences
				.map((preference) => preference.tags)
				.flat()
				.findIndex((tag) => tag.state === 'checked') !== -1,
		[userPreferences]
	)

	const handleFieldSelect = async (options: SelectOptions) => {
		const request: PreferenceTag[] = options.map((option) => ({
			text: option.title,
			state: option.state,
			step: (() => {
				let step = 0

				for (let i = 0; i < userPreferences.length; i++) {
					const element = userPreferences[i]
					const index = element.tags.findIndex(
						(tag) => tag.text === option.title
					)
					if (index !== -1) {
						step = element.tags[index].step
						break
					}
				}

				return step
			})(),
		}))

		if (user.role === 'stylist') {
			await setStylistPreferences({
				id: user.id,
				token,
				request,
			})
		}

		if (user.role === 'client') {
			// TODO: Add setClientPreferences API
			// await setClientPreferences({
			// 	id: user.id,
			// 	token,
			// 	request,
			// })
		}
	}

	if (isPreferencesLoading)
		return (
			<Block isContentCentered>
				<Spinner color="#FF0C63" />
			</Block>
		)
	if (userInfo.status === 'draft') return null
	if (!hasCheckedPreferences) return null

	return (
		<div style={isPreview ? { pointerEvents: 'none' } : {}}>
			{filteredUserPreferences.map((preference, index) => (
				<Block key={index} margin="0 0 48px 0">
					<CheckBoxesComponent
						isMultipleClick={preference.possibleStages.length > 2}
						isMultipleChoice
						label={preference.title}
						fields={preference.tags.map((tag) => ({
							title: tag.text,
							state: tag.state,
						}))}
						onFieldsSelect={(value) => handleFieldSelect(value)}
					/>
				</Block>
			))}
		</div>
	)
}
