import { PlusOutlined } from '@ant-design/icons'
import type { InputRef } from 'antd'
import {
	Button as ButtonAnt,
	Divider,
	Input as InputAnt,
	Select,
	Space,
} from 'antd'
import { FocusEventHandler, useEffect, useRef, useState } from 'react'
import { PlaceHolder, SelectWrapper } from './select-plus-add-styled'

export type SelectPlusAddProps = {
	mode?: 'multiple' | 'tags' | undefined
	placeholder?: string
	defaultValue?: string
	width?: number
	onChange: (value: string) => void
	onBlur?: FocusEventHandler<HTMLElement>
	options: string[]
	isDisabled?: boolean
	searchEnabled?: boolean
	addItemFeature?: boolean
	multiItemsType?: 'text' | 'block' | 'client'
}

export function CustomSelectPlusAdd({
	defaultValue,
	mode = undefined,
	onChange,
	onBlur,
	options,
	placeholder,
	width,
	isDisabled,
	searchEnabled,
	addItemFeature,
	multiItemsType = 'text',
}: SelectPlusAddProps) {
	const inputRef = useRef<InputRef>(null)
	const [items, setItems] = useState<string[]>(options)
	const [name, setName] = useState('')

	let index = 0

	const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value)
	}

	const addItem = (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => {
		e.preventDefault()
		setItems([...items, name || `New item ${index++}`])
		setName('')
		setTimeout(() => {
			inputRef.current?.focus()
		}, 0)
	}

	useEffect(() => {
		options && setItems(options)
	}, [options])

	const dropdownBrandSelectorRender = (menu: any) => (
		<>
			{menu}
			<Divider style={{ margin: '8px 0' }} />
			<Space style={{ padding: '0 8px 4px' }}>
				<InputAnt
					placeholder="Please enter item"
					ref={inputRef}
					value={name}
					onChange={onNameChange}
				/>
				<ButtonAnt type="text" icon={<PlusOutlined />} onClick={addItem}>
					Add item
				</ButtonAnt>
			</Space>
		</>
	)

	if (addItemFeature) {
		return (
			<SelectWrapper width={width} multiItemsType={multiItemsType}>
				<PlaceHolder>{placeholder}</PlaceHolder>
				<Select
					mode={mode}
					value={defaultValue}
					placeholder={placeholder}
					onChange={onChange}
					options={items.map((item) => ({ label: item, value: item }))}
					disabled={isDisabled}
					onBlur={onBlur}
					showSearch={searchEnabled}
					optionFilterProp={searchEnabled ? 'children' : ''}
					filterOption={
						searchEnabled
							? (input, option) => (option?.label ?? '').includes(input)
							: () => {
									return false
							  }
					}
					filterSort={
						searchEnabled
							? (optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
							: () => {
									return 0
							  }
					}
					dropdownRender={dropdownBrandSelectorRender}
				/>
			</SelectWrapper>
		)
	}

	return <></>
}
