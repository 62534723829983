import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { ClientInfo } from '@typings/types'
import { Block, InfoTextBlock } from '@ui/index'
import { Col } from 'antd'
import moment from 'moment'
import { useMemo } from 'react'
import {
	ContentBlock,
	FormWrapper,
	TitleBlock,
} from './personal-data-form-styled'

export function PersonalDataForm({
	clientDetails,
}: {
	clientDetails: ClientInfo
}) {
	const client = clientDetails
	const { userPreferences } = useAppSelector(userSelector)
	const bodyShape = useMemo(
		() =>
			userPreferences
				.find((preference) => preference.title === 'Figure')
				?.tags.find((tag) => tag.state === 'checked')
				?.text.toLowerCase(),
		[userPreferences]
	)

	return (
		<Col
			xs={{ span: 24 }}
			sm={{ span: 24 }}
			md={{ flex: '0 1 49%' }}
			xl={{ flex: '0 1 33%' }}
		>
			<FormWrapper>
				<TitleBlock>Personal details</TitleBlock>
				<ContentBlock>
					<Block isFlexBlock isFullWidth gap="15px">
						<InfoTextBlock text={client.sex || ''} label={'Sex'} />
						<InfoTextBlock
							text={
								client.params?.birthday
									? moment(new Date(client.params?.birthday)).format(
											'YYYY-MM-DD'
									  )
									: ''
							}
							label={'Date of birth'}
						/>
					</Block>
					<Block isFlexBlock isFullWidth gap="15px">
						<InfoTextBlock
							text={client.params?.country || ''}
							label={'Country'}
						/>
						<InfoTextBlock text={client.params?.city || ''} label={'City'} />
					</Block>
					<Block isFlexBlock isFullWidth gap="15px">
						<InfoTextBlock text={bodyShape || ''} label={'Body shape'} />
						<InfoTextBlock
							text={client.params?.clientHeight || ''}
							label={'Height'}
						/>
					</Block>
				</ContentBlock>
			</FormWrapper>
		</Col>
	)
}
