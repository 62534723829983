import { Col, Row } from 'antd'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useNavigate } from 'react-router-dom'

import { CapsuleInfo } from '@typings/types'
import {
	ArrowNext,
	ArrowPrev,
} from '@ui/gallery-buttons/gallery-buttons-styled'
import { Icon, Skeleton, Swap } from '@ui/index'
import { CapsulesItem } from '../capsules-item'
import { Container, LoaderCont } from './gallery-block-styled'

type Props = {
	carouselRef: any
	imageList: CapsuleInfo[]
}

export function GalleryBlock({ carouselRef, imageList }: Props) {
	const navigate = useNavigate()

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 4,
		},
		largeDesktop: {
			breakpoint: { max: 3000, min: 1600 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 1600, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1424, min: 579 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 579, min: 0 },
			items: 1,
		},
	}

	const Loader = () => {
		return (
			<LoaderCont>
				{[1, 2, 3].map((item) => (
					<Skeleton key={item} width={'100%'} height={452}>
						<rect x="0" y="0" rx="6" ry="6" width="100%" height={452} />
					</Skeleton>
				))}
			</LoaderCont>
		)
	}

	return (
		<Row gutter={0} align="middle">
			<Col span={18} className="row-block-label">
				My work
			</Col>
			<Col span={6} />

			<Col xs={{ span: 3 }} sm={{ span: 2 }}>
				{imageList.length > 1 && (
					<ArrowPrev
						leftIcon={<Icon color="#FFF" name="arrowDownIcon" />}
						onClick={() => carouselRef.current?.previous()}
						skin="white-outline"
						size="m"
						isFitContent
					/>
				)}
			</Col>
			<Col xs={{ span: 18 }} sm={{ span: 20 }} style={{ marginTop: 58 }}>
				<Swap is={!imageList.length} isSlot={<Loader />}>
					<Carousel
						ref={(el) => (carouselRef.current = el)}
						responsive={responsive}
						arrows={false}
						containerClass="carousel-container"
						renderButtonGroupOutside={true}
						centerMode={false}
					>
						{imageList.map((item) => (
							<Container key={item.id}>
								<CapsulesItem
									item={item}
									onClick={() => {
										navigate(`/capsules/${item.id}`, {
											state: {
												capsuleId: item.id,
											},
										})
									}}
								/>
							</Container>
						))}
					</Carousel>
				</Swap>
			</Col>
			<Col xs={{ span: 3 }} sm={{ span: 2 }}>
				{imageList.length > 1 && (
					<ArrowNext
						leftIcon={<Icon color="#FFF" name="arrowDownIcon" />}
						onClick={() => carouselRef.current?.next()}
						skin="white-outline"
						size="m"
						isFitContent
					/>
				)}
			</Col>
		</Row>
	)
}
