import { Button } from '@ui/button'
import { Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
	BlockWrapper,
	GapLineCol,
	ImageBlock,
	InterviewMsg,
	TextBlockCol,
} from './wait-approve-screen-styled'

type Props = {
	name: string
}

export function WaitApproveScreen(props: Props) {
	const navigate = useNavigate()

	return (
		<BlockWrapper
			gutter={[
				{ xs: 12, sm: 12, md: 12, lg: 24 },
				{ xs: 12, sm: 12, md: 12, lg: 24 },
			]}
		>
			<Col
				xs={{ span: 24 }}
				sm={{ span: 24 }}
				lg={{ span: 2 }}
				xl={{ span: 2 }}
				xxl={{ span: 4 }}
			>
				<GapLineCol />
			</Col>
			<Col
				xs={{ span: 24 }}
				sm={{ span: 24 }}
				lg={{ span: 8 }}
				xl={{ span: 8 }}
				xxl={{ span: 6 }}
			>
				<TextBlockCol>
					Thank you, {props.name}.<br /> We have received your application
				</TextBlockCol>
				<InterviewMsg>
					We will contact you and conduct a brief interview <br />
					<br />
					Now you can take a quiz to start match with the clients after the
					interview
				</InterviewMsg>

				<Button
					isSubmit
					skin="dark"
					onClick={() => {
						navigate('/quiz')
					}}
				>
					Take a quiz
				</Button>
			</Col>
			<Col
				xs={{ span: 24 }}
				sm={{ span: 24 }}
				lg={{ span: 14 }}
				xl={{ span: 14 }}
				xxl={{ span: 14 }}
			>
				<ImageBlock />
			</Col>
		</BlockWrapper>
	)
}
