import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'

import Avatar from '@components/avatar/avatar'
import { GalleryBlock } from '@components/gallery-block/gallery-block'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { SdNotification } from '@components/sd-notification'
import {
	Block,
	Button,
	CustomSelect,
	GrayLine,
	Input,
	Spinner,
	Swap,
} from '@ui/index'
import { RowStyled } from './styleboards-new-page-styled'
import { useStyleboardsNewPage } from './use-styleboards-new-page'

export function StyleBoardsNewPage() {
	const {
		clientLabel,
		clientAvatarUrl,
		clientListToShow,
		currentPage,
		errors,
		handleAddNewPhoto,
		handleChangeTitle,
		handleDeleteClick,
		handlePublish,
		handleSaveAsDraft,
		handleLinkClient,
		handleUnlinkClient,
		handleSetCoverClick,
		handleDuplicateStyleboard,
		imageItems,
		isLoading,
		isClientLoading,
		isNewPhotoLoading,
		isSelectedClientError,
		navigationButtons,
		register,
		styleboard,
		watch,
	} = useStyleboardsNewPage()

	if (isLoading) {
		return (
			<Block isFullWidth margin="0 48px 48px">
				<PageNavigation
					showListType={currentPage}
					buttons={navigationButtons}
				/>
				<Spinner color="#FF5656" />
			</Block>
		)
	}

	return (
		<Block isFullWidth margin="0 48px 48px">
			<PageNavigation showListType={currentPage} buttons={navigationButtons} />

			<Row gutter={[10, 20]}>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 5 }}
					xl={{ span: 5 }}
					xxl={{ span: 6 }}
					className="row-block-label"
				>
					Styleboard details
				</Col>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 19 }}
					xl={{ span: 19 }}
					xxl={{ span: 18 }}
				>
					<div className="col-block-label-h3">Name the styleboard</div>
				</Col>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 19, offset: 5 }}
					xl={{ span: 19, offset: 5 }}
					xxl={{ span: 18, offset: 6 }}
				>
					<Row gutter={10}>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							md={{ span: 20 }}
							xxl={{ span: 17 }}
							xl={{ span: 18 }}
						>
							<Input
								hasValue={!!watch('title')}
								label="title"
								placeholder="Enter a name"
								inputFieldSlot={
									<input
										{...register('title')}
										onBlur={handleChangeTitle}
										type="text"
									/>
								}
								isError={!!errors.title}
								errorText={errors.title?.message}
							/>
						</Col>
					</Row>
				</Col>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 19, offset: 5 }}
					xl={{ span: 19, offset: 5 }}
					xxl={{ span: 18, offset: 6 }}
					style={{ marginTop: 25 }}
				>
					<div className="col-block-label-h3">
						Link the styleboard with the client
					</div>
				</Col>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 19, offset: 5 }}
					xl={{ span: 19, offset: 5 }}
					xxl={{ span: 18, offset: 6 }}
				>
					<Row gutter={[10, 20]}>
						<Col
							xs={{ span: 24 }}
							sm={{ span: !!clientLabel.length ? 17 : 24 }}
							md={{ span: !!clientLabel.length ? 14 : 17 }}
							xxl={{ span: !!clientLabel.length ? 14 : 17 }}
							xl={{ span: !clientLabel.length ? 14 : 18 }}
						>
							<CustomSelect
								mode="tags"
								defaultValue={clientLabel}
								searchEnabled
								placeholder="Enter client name"
								onChange={handleLinkClient}
								options={clientListToShow}
								isDisabled={isClientLoading || !!clientLabel.length}
								multiItemsType="client"
								isError={isSelectedClientError}
								tagRender={(props) => {
									return (
										<Block isFlexBlock gap="8px">
											<Avatar imageUrl={clientAvatarUrl ?? ''} size={24} />{' '}
											{clientLabel}
										</Block>
									)
								}}
							/>
						</Col>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 7 }}
							md={{ span: 6 }}
							xxl={{ span: 3 }}
							xl={{ span: 4 }}
						>
							{!!clientLabel.length && (
								<Button
									isDisabled={isClientLoading}
									onClick={handleUnlinkClient}
									style={{ height: '100%', maxHeight: 'unset' }}
								>
									Unlink client
								</Button>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
			<RowStyled>
				<GrayLine />
			</RowStyled>
			<Row gutter={[10, 20]}>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 5 }}
					xl={{ span: 5 }}
					xxl={{ span: 6 }}
					className="row-block-label"
				>
					Upload 8 photos
				</Col>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 19 }}
					xl={{ span: 19 }}
					xxl={{ span: 18 }}
				>
					<Block isFlexBlock gap="8px">
						<div className="col-block-label-h3">Photos</div>
						<div style={{ marginRight: 16 }}>({imageItems.length})</div>
						<SdNotification text="Pin a cover photo" />
					</Block>
				</Col>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 19, offset: 5 }}
					xl={{ span: 19, offset: 5 }}
					xxl={{ span: 18, offset: 6 }}
				>
					<GalleryBlock
						aspect={270 / 276}
						imageItems={imageItems}
						isNewPhotoLoading={isNewPhotoLoading}
						handleAddNewPhoto={handleAddNewPhoto}
						handleSetCoverClick={handleSetCoverClick}
						handleDeleteClick={handleDeleteClick}
					/>
				</Col>
			</Row>
			<RowStyled>
				<GrayLine />
			</RowStyled>
			<Row gutter={[10, 20]}>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 5 }}
					xl={{ span: 5 }}
					xxl={{ span: 6 }}
					className="row-block-label"
				>
					Share settings
				</Col>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 19 }}
					xl={{ span: 19 }}
					xxl={{ span: 18 }}
				>
					<SdNotification
						text="We recommend reviewing your styleboard before sharing."
						linkSlot={<Link to={`/styleboards/${styleboard.id}`}>Preview</Link>}
					/>
					<Row gutter={48} style={{ marginTop: 24 }}>
						<Col xs={{ span: 24 }} md={{ span: 8 }}>
							<Button
								isSubmit
								isDisabled={false}
								skin="dark"
								margin="0"
								onClick={handlePublish}
							>
								{['restyle', 'published'].indexOf(styleboard.status) > -1
									? 'Update & Share'
									: 'Share styleboard'}
							</Button>
						</Col>
						<Swap
							is={styleboard.status === 'draft'}
							isSlot={
								<Col xs={{ span: 24 }} md={{ span: 8 }}>
									<Button
										isSubmit
										isDisabled={false}
										skin="light-link"
										margin="0"
										onClick={handleSaveAsDraft}
									>
										Save as draft
									</Button>
								</Col>
							}
						>
							<Col xs={{ span: 24 }} md={{ span: 8 }}>
								<Button
									isSubmit
									isDisabled={false}
									skin="light-link"
									margin="0"
									onClick={handleDuplicateStyleboard}
								>
									Duplicate styleboard
								</Button>
							</Col>
						</Swap>
					</Row>
				</Col>
			</Row>
		</Block>
	)
}
