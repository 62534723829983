import { Col, Row, Select } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'

import { screenSize } from '@styles/screen-sizes'
import { fontRoboto, regular16 } from '@styles/typography'
import { Button } from '@ui/index'

export const UploadButton = styled(Button)`
	align-self: end;
	line-height: 22px;
`

export const ColFlex = styled(Col)`
	display: flex;
	flex-wrap: wrap;
`

export const ItemDataWrapper = styled.div`
	&.disabled {
		opacity: 0.2;
		pointer-events: none;
	}
`

export const SelectStyled = styled(Select)`
	.ant-select-selection-item {
		${fontRoboto}
		${regular16}
		color: #000;
		padding-inline-end: 0 !important;
	}

	.ant-select-arrow {
		color: #000;
		height: 22px !important;
		margin-top: -8px !important;
	}

	.ant-select-selector {
		height: 56px !important;
		padding: 16px 12px !important;
	}

	.ant-select-selector .ant-select-selection-item {
		line-height: unset !important;
	}
	.ant-select-dropdown .ant-select-item {
		padding: 5px 10px;
	}
`
export const InlineStyle = createGlobalStyle`
	.input-text-wrapper {
		border-bottom-left-radius: 0 !important;
		border-top-left-radius: 0 !important;

		& div {
			border-bottom-left-radius: 0 !important;
			border-top-left-radius: 0 !important;
		}

		& .input-text-container {
			padding: 3px;
			padding-left: 6px !important;
		}
	}
`

export const ColUpload = styled(Col)`
	& > .ant-upload-wrapper,
	.ant-upload-select {
		width: 100%;
		max-width: 268px;
		margin: 0 auto;
		display: block;
	}
`

export const RowStyled = styled(Row)`
	margin-bottom: 24px;
	margin-top: 34px;

	@media ${screenSize.antSm} {
		margin-bottom: 24px;
		margin-top: 34px;
	}
	@media ${screenSize.antMd} {
		margin-bottom: 34px;
		margin-top: 54px;
	}
	@media ${screenSize.antLg} {
		margin-bottom: 44px;
		margin-top: 64px;
	}
`
