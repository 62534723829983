import { Col, Row } from 'antd'

import { PageNavigation } from '@components/page-navigation'
import { SdNotification } from '@components/sd-notification'
import { DatePickerSD } from '@ui/date-picker'
import { Button, CustomSelect, GrayLine, Icon, Input, Spinner } from '@ui/index'
import { PreferencesBlock } from '../../preferences-block/preferences-block'
import { CvForm } from '../cv-form/cv-form'
import { TitleBlock } from '../stylist-registration-form-styled'
import { UploadAvatar } from '../upload-avatar/upload-avatar'
import { FormWrapper, RowStyled } from './personal-data-styled'
import { usePersonalDataForm } from './use-personal-data-form'

export function PersonalDataForm() {
	const {
		cities,
		countries,
		errors,
		genderOptions,
		handleCitySelect,
		handleCountrySelect,
		handleGenderSelect,
		handleSetUserInfo,
		handleSignUp,
		handleTakeQuiz,
		isCityDisabled,
		isCountriesLoading,
		isLoading,
		isSettingsLoading,
		isUserLoading,
		navigationButtons,
		onDatePickerChange,
		register,
		submitButtonText,
		userInfo,
		watch,
		setValue,
	} = usePersonalDataForm()

	const moderationNeedStatus = userInfo.status === 'moderation-need'
	const stylistLabelText = moderationNeedStatus
		? 'Complete your <br/> StyleDyle stylist profile'
		: 'My profile'

	if (isUserLoading) {
		return <Spinner />
	}

	return (
		<FormWrapper>
			<PageNavigation showListType="edit" buttons={navigationButtons} />
			<Row gutter={[48, 24]}>
				<Col
					md={{ span: 24 }}
					lg={{ span: 24 }}
					xxl={{ span: 6 }}
					xl={{ span: 4 }}
					className="row-block-label"
					dangerouslySetInnerHTML={{ __html: stylistLabelText }}
				/>

				<Col
					xs={{ span: 24 }}
					lg={{ span: 24 }}
					xxl={{ span: 18 }}
					xl={{ span: 19 }}
				>
					{moderationNeedStatus && (
						<SdNotification text="Check out our portfolio guide 👉 ▶️ and StyleDyle portfolio example" />
					)}
				</Col>
				<Col
					xs={{ span: 24, offset: 0 }}
					sm={{ span: 24, offset: 0 }}
					md={{ span: 8, offset: 0 }}
					xxl={{ span: 4, offset: 6 }}
					xl={{ span: 6, offset: 4 }}
					lg={{ span: 8, offset: 0 }}
					offset={6}
				>
					<Row
						gutter={[0, 26]}
						style={{ display: 'flex', flexDirection: 'column' }}
					>
						<TitleBlock>Your photo</TitleBlock>
						<UploadAvatar />
					</Row>
				</Col>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 14 }}
					lg={{ span: 14 }}
					xl={{ span: 14 }}
					xxl={{ span: 14 }}
				>
					<Row gutter={[0, 26]}>
						<TitleBlock>Personal data</TitleBlock>
						<Row gutter={[12, 24]}>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 12 }}
								md={{ span: 12 }}
								xxl={{ span: 8 }}
								xl={{ span: 10 }}
							>
								<Input
									label="firstName"
									inputFieldSlot={
										<input
											{...register('firstName', {
												required: 'Please enter your first name.',
											})}
											onBlur={(e: any) => {
												if (e.target.value === '') {
													handleSetUserInfo(
														'first_name',
														userInfo.stylist.first_name
													)
													setValue('firstName', userInfo.stylist.first_name)
													return
												}

												handleSetUserInfo('first_name', e.target.value)
											}}
											type="text"
											defaultValue={userInfo.stylist.first_name}
										/>
									}
									errorText={errors.firstName && errors.firstName.message}
									placeholder="Name"
									hasValue={!!watch('firstName')}
								/>
							</Col>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 12 }}
								md={{ span: 12 }}
								xxl={{ span: 8 }}
								xl={{ span: 10 }}
							>
								<Input
									label="lastName"
									inputFieldSlot={
										<input
											{...register('lastName', {
												required: 'Please enter your last name.',
											})}
											onBlur={(e: any) =>
												handleSetUserInfo('last_name', e.target.value)
											}
											type="text"
											defaultValue={userInfo.stylist.last_name}
										/>
									}
									errorText={errors.lastName && errors.lastName.message}
									placeholder="Surname"
									hasValue={!!watch('lastName')}
								/>
							</Col>
							<Col
								xs={{ span: 0 }}
								sm={{ span: 0 }}
								md={{ span: 0 }}
								lg={{ span: 0 }}
								xl={{ span: 8 }}
								flex="1"
							></Col>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 12 }}
								md={{ span: 12 }}
								xxl={{ span: 8 }}
								xl={{ span: 10 }}
							>
								<DatePickerSD
									placeholder="Date of birth"
									defaultValue={userInfo.stylist.params.birthday?.[0]}
									onChange={onDatePickerChange}
									suffixIcon={<Icon name="calendarIcon" />}
								/>
							</Col>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 12 }}
								md={{ span: 12 }}
								xxl={{ span: 8 }}
								xl={{ span: 10 }}
							>
								<CustomSelect
									isDisabled={isSettingsLoading}
									placeholder="Gender"
									defaultValue={userInfo.stylist.sex}
									onChange={handleGenderSelect}
									options={genderOptions}
								/>
							</Col>
							<Col
								xs={{ span: 0 }}
								sm={{ span: 0 }}
								md={{ span: 0 }}
								lg={{ span: 0 }}
								xl={{ span: 8 }}
								flex="1"
							></Col>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 12 }}
								md={{ span: 12 }}
								xxl={{ span: 8 }}
								xl={{ span: 10 }}
							>
								<CustomSelect
									isDisabled={isCountriesLoading}
									placeholder="Country"
									defaultValue={userInfo.stylist.params.country}
									onChange={handleCountrySelect}
									options={countries}
									searchEnabled={true}
								/>
							</Col>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 12 }}
								md={{ span: 12 }}
								xxl={{ span: 8 }}
								xl={{ span: 10 }}
							>
								<CustomSelect
									placeholder="City"
									defaultValue={userInfo.stylist.params.city}
									onChange={handleCitySelect}
									isDisabled={isCountriesLoading || isCityDisabled}
									options={cities}
									searchEnabled={true}
								/>
							</Col>
							<Col
								xs={{ span: 24 }}
								sm={{ span: 24 }}
								md={{ span: 24 }}
								xxl={{ span: 16 }}
								xl={{ span: 20 }}
							>
								<Input
									type="textArea"
									label="questionArea"
									inputFieldSlot={
										<textarea
											{...register('questionArea')}
											rows={4}
											defaultValue={userInfo.stylist.notes ?? ''}
											onBlur={(e: any) =>
												handleSetUserInfo('notes', e.target.value)
											}
										/>
									}
									errorText={errors.questionArea && errors.questionArea.message}
									placeholder="Craft a brief to introduce yourself to potentian clients"
									hasValue={!!watch('questionArea')}
								/>
							</Col>
						</Row>
					</Row>
					<Row gutter={[12, 16]} style={{ marginTop: '24px' }}>
						<Col span={24}>
							<TitleBlock>Main contacts</TitleBlock>
						</Col>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={{ span: 12 }}
							xxl={{ span: 8 }}
							xl={{ span: 10 }}
						>
							<Input
								label="phone"
								inputFieldSlot={
									<input
										{...register('phone', {
											required: 'Please enter your phone.',
										})}
										type="text"
										defaultValue={userInfo.phone}
										onBlur={(e: any) =>
											handleSetUserInfo('phone', e.target.value)
										}
									/>
								}
								errorText={errors.phone && errors.phone.message}
								placeholder="Phone number *"
								hasValue={!!watch('phone')}
							/>
						</Col>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={{ span: 12 }}
							xxl={{ span: 8 }}
							xl={{ span: 10 }}
						>
							<Input
								label="email"
								inputFieldSlot={
									<input
										{...register('email')}
										type="text"
										defaultValue={userInfo.email}
										onBlur={(e: any) => {
											handleSetUserInfo('email', e.target.value)
										}}
									/>
								}
								errorText={errors.email && errors.email.message}
								placeholder="Email *"
								hasValue={!!watch('email')}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<RowStyled>
				<GrayLine />
			</RowStyled>
			<Row gutter={[48, 24]}>
				<CvForm />
			</Row>
			<RowStyled>
				<GrayLine />
			</RowStyled>
			<Row gutter={[48, 24]}>
				<Col
					md={{ span: 24 }}
					xxl={{ span: 6 }}
					xl={{ span: 4 }}
					className="row-block-label"
				>
					My preferences
				</Col>
				<Col lg={{ span: 24 }} xxl={{ span: 18 }} xl={{ span: 20 }}>
					<PreferencesBlock
						user={{
							id: userInfo?.stylist?.id,
							role: 'stylist',
						}}
					/>
				</Col>
			</Row>
			<RowStyled>
				<GrayLine />
			</RowStyled>
			<Row style={{ marginTop: 16 }} justify="center">
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 24 }}
					xxl={{ span: 18, offset: 6 }}
					xl={{ span: 20, offset: 4 }}
				>
					<Row justify={{ md: 'center', xl: 'start' }} gutter={[16, 16]}>
						{userInfo.status !== 'active' && (
							<Col xs={{ span: 24 }} sm={{ span: 4 }}>
								<Button
									isSubmit
									isDisabled={isLoading}
									skin="grey-outline"
									onClick={() => {}}
								>
									Save
								</Button>
							</Col>
						)}
						{userInfo.status !== 'draft' && (
							<Col xs={{ span: 24 }} sm={{ span: 9 }}>
								<Button skin="dark" onClick={handleTakeQuiz}>
									Take a quiz to match with the clients
								</Button>
							</Col>
						)}
						{userInfo.status === 'draft' && (
							<Col span={9}>
								<Button
									isSubmit
									isDisabled={isLoading}
									skin="dark"
									onClick={handleSignUp}
								>
									{submitButtonText}
								</Button>
								<div>{errors.avatar?.message}</div>
							</Col>
						)}
					</Row>
				</Col>
			</Row>
		</FormWrapper>
	)
}
